import React from 'react';
export interface IEveQuickOrderButtonRowResources {
    addAllToCartText: string;
    clearAllText: string;
    addMoreRowsText: string;
}

interface IEveQuickOrderButtonRowProps {
    resources: IEveQuickOrderButtonRowResources;
    canAddToCart: boolean;
    handleAddToCart: () => void;
    handleClearAll: () => void;
    isLoading: boolean;
}

export const EveQuickOrderButtonRow: React.FC<IEveQuickOrderButtonRowProps> = props => {
    const {
        resources: { clearAllText, addAllToCartText },
        canAddToCart,
        handleClearAll,
        handleAddToCart,
        isLoading
    } = props;
    const _className = 'qo-button';

    return (
        <div className={`${_className}__controls`}>
            <button
                className={`${_className} ${_className}__controls--add-to-cart`}
                disabled={!canAddToCart || isLoading}
                onClick={handleAddToCart}
                type={'button'}
            >
                {isLoading ? 'Loading...' : addAllToCartText}
            </button>
            <button className={`${_className} ${_className}__controls--clear-form`} onClick={handleClearAll} type={'button'}>
                {clearAllText}
            </button>
        </div>
    );
};
